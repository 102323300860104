/**
 * Tilled Admin API
 * Tilled Internal Admin API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: integrations@tilled.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AdminPermissionSet { 
    /**
     * Time at which the object was created.
     */
    created_at: string;
    /**
     * Time at which the object was deleted.
     */
    deleted_at?: string;
    /**
     * Is this permission set allowed to be used
     */
    enabled: boolean;
    /**
     * Unique identifier for the object.
     */
    id: string;
    /**
     * The id of the last admin user to update this permission set.
     */
    last_updated_by: string;
    /**
     * User-friendly name (e.g. \'Read-only default\')
     */
    name: string;
    /**
     * The list of permissions for this set.
     */
    permissions: Array<AdminPermissionSet.PermissionsEnum>;
    /**
     * Time at which the object was last updated.
     */
    updated_at: string;
}
export namespace AdminPermissionSet {
    export type PermissionsEnum = '*' | 'feature_toggles:read' | 'feature_toggles:write' | 'business_profile.tax_id:read' | 'business_profile.tax_id:write' | 'user_invitations:write' | 'product_codes:write' | 'pricing_templates:write' | 'partner_revenue_share_schedules:write' | 'buy_rates:write' | 'fee_schedules:write' | 'paysafe_secrets:read' | 'paysafe_secrets:write' | 'handpoint_secrets:read' | 'handpoint_secrets:write' | 'admin_permission_sets:read' | 'admin_permission_sets:write' | 'admin_user_groups:read' | 'admin_user_groups:write' | 'request_logs:read' | 'files.onboarding_documentation:read' | 'files.onboarding_documentation:write' | 'subscriptions:cancel' | 'bank_verification:read' | 'bank_verification:write' | 'payment_methods:export';
    export const PermissionsEnum = {
        STAR: '*' as PermissionsEnum,
        FEATURE_TOGGLESREAD: 'feature_toggles:read' as PermissionsEnum,
        FEATURE_TOGGLESWRITE: 'feature_toggles:write' as PermissionsEnum,
        BUSINESS_PROFILE_TAX_IDREAD: 'business_profile.tax_id:read' as PermissionsEnum,
        BUSINESS_PROFILE_TAX_IDWRITE: 'business_profile.tax_id:write' as PermissionsEnum,
        USER_INVITATIONSWRITE: 'user_invitations:write' as PermissionsEnum,
        PRODUCT_CODESWRITE: 'product_codes:write' as PermissionsEnum,
        PRICING_TEMPLATESWRITE: 'pricing_templates:write' as PermissionsEnum,
        PARTNER_REVENUE_SHARE_SCHEDULESWRITE: 'partner_revenue_share_schedules:write' as PermissionsEnum,
        BUY_RATESWRITE: 'buy_rates:write' as PermissionsEnum,
        FEE_SCHEDULESWRITE: 'fee_schedules:write' as PermissionsEnum,
        PAYSAFE_SECRETSREAD: 'paysafe_secrets:read' as PermissionsEnum,
        PAYSAFE_SECRETSWRITE: 'paysafe_secrets:write' as PermissionsEnum,
        HANDPOINT_SECRETSREAD: 'handpoint_secrets:read' as PermissionsEnum,
        HANDPOINT_SECRETSWRITE: 'handpoint_secrets:write' as PermissionsEnum,
        ADMIN_PERMISSION_SETSREAD: 'admin_permission_sets:read' as PermissionsEnum,
        ADMIN_PERMISSION_SETSWRITE: 'admin_permission_sets:write' as PermissionsEnum,
        ADMIN_USER_GROUPSREAD: 'admin_user_groups:read' as PermissionsEnum,
        ADMIN_USER_GROUPSWRITE: 'admin_user_groups:write' as PermissionsEnum,
        REQUEST_LOGSREAD: 'request_logs:read' as PermissionsEnum,
        FILES_ONBOARDING_DOCUMENTATIONREAD: 'files.onboarding_documentation:read' as PermissionsEnum,
        FILES_ONBOARDING_DOCUMENTATIONWRITE: 'files.onboarding_documentation:write' as PermissionsEnum,
        SUBSCRIPTIONSCANCEL: 'subscriptions:cancel' as PermissionsEnum,
        BANK_VERIFICATIONREAD: 'bank_verification:read' as PermissionsEnum,
        BANK_VERIFICATIONWRITE: 'bank_verification:write' as PermissionsEnum,
        PAYMENT_METHODSEXPORT: 'payment_methods:export' as PermissionsEnum
    };
}


