/**
 * Tilled Admin API
 * Tilled Internal Admin API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: integrations@tilled.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface TerminalReaderCreateParams { 
    /**
     * The merchant provided description of the terminal reader
     */
    description?: string;
    /**
     * The provider of the terminal reader
     */
    provider: TerminalReaderCreateParams.ProviderEnum;
    /**
     * The serial number of the terminal reader
     */
    serial_number: string;
    /**
     * The type of card reader
     */
    type: TerminalReaderCreateParams.TypeEnum;
}
export namespace TerminalReaderCreateParams {
    export type ProviderEnum = 'aevi' | 'handpoint';
    export const ProviderEnum = {
        AEVI: 'aevi' as ProviderEnum,
        HANDPOINT: 'handpoint' as ProviderEnum
    };
    export type TypeEnum = 'pax_a920' | 'pax_a920_pro' | 'pax_a60' | 'pax_a77' | 'pax_a80';
    export const TypeEnum = {
        A920: 'pax_a920' as TypeEnum,
        A920_PRO: 'pax_a920_pro' as TypeEnum,
        A60: 'pax_a60' as TypeEnum,
        A77: 'pax_a77' as TypeEnum,
        A80: 'pax_a80' as TypeEnum
    };
}


