<mat-toolbar matDialogTitle class="mat-primary m-0">
  <mat-toolbar-row class="flex">
    <span class="dialog-title flex-1 truncate">Request Information</span>
    <button
      class="close-icon"
      mat-icon-button
      (click)="matDialogRef.close()"
      aria-label="Close dialog"
    >
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar-row>
</mat-toolbar>

<div class="pt-8 max-w-128">
  <div
    class="text-lg tracking-tight text-tilled-secondary break-normal w-full pb-6 text-center"
  >
    Requesting information updates the application status back to 'Started' and
    preserves all existing inputs.
  </div>

  <div
    class="text-lg font-bold tracking-tight text-tilled-secondary break-normal w-full pb-6 text-center"
  >
    This application was not submitted to the processor yet.
  </div>

  <div
    class="text-lg tracking-tight text-tilled-secondary break-normal w-full pb-6 text-center"
  >
    Since this application has not been submitted to the processor, Merchants
    can login to the merchant application to edit all of the application fields
    and resubmit.
  </div>

  <div
    class="text-lg tracking-tight text-tilled-secondary break-normal w-full pb-6 text-center"
  >
    Reach out directly to the merchant/partner with specific instructions to
    help resolve the issue with the application.
  </div>

  <div class="flex w-full justify-end">
    <!-- Cancel button -->
    <button class="mr-4" mat-button (click)="cancelClicked()">Cancel</button>
    <!-- Request info button -->
    <button mat-flat-button color="primary" (click)="requestInfoClicked()">
      <span>Request Info</span>
    </button>
  </div>
</div>
