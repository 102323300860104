import { Component, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-request-application-info-dialog',
  templateUrl: './request-application-info-dialog.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class RequestApplicationInfoDialogComponent {
  constructor(
    public matDialogRef: MatDialogRef<RequestApplicationInfoDialogComponent>,
  ) {}

  public cancelClicked() {
    this.matDialogRef.close(false);
  }

  public requestInfoClicked() {
    this.matDialogRef.close(true);
  }
}
